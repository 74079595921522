import * as React from "react";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import {infoBlock} from "../../scss-modules/terms.module.scss";

const bannerImg = '../../banners/about-us_banner.jpg';


//RENDER
export default function PrivacyPolicyPage() {
    return (
        <Layout pageTitle={'Privacy Policy'}>
            <SplashBanner
                title={"Privacy Policy"}
                content={''}
                backgroundImg={bannerImg}
            />

            <section id="privacyPolicy">
                <div className="container">

                    <p><strong>Performance Energy complies with data protection laws globally, with the minimum standard
                        that
                        we apply being that found within the General Data Protection Regulation (GDPR) (Regulation (EU)
                        2016/679). The GDPR enhances privacy rights for individuals and provides a strict framework
                        within which commercial organisations can legally operate.</strong></p>

                    <div className={infoBlock}>
                        <h3>1.Introduction</h3>
                        <p>1.1 Performance Energy (''The Company'', ''we'', ''us'', ''our'' or ''business group'') are
                            committed to protecting the privacy of the users / viewers of this website. This Privacy
                            Policy
                            explains how we will use/store personal information that is submitted or collected when you
                            use
                            this website and its additional links. We advise that all users read this policy carefully
                            and
                            are fully aware of the terms of the policy before using.</p>
                        <p>The Company may amend this Privacy Policy from time to time. Please visit this page if you
                            want
                            to stay up to date, as we will post any changes here.</p>
                        <p>The policy was updated on 13th October 2019</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>2. Information Collected</h3>
                        <p>2.1 To provide the visitors of this website the best opportunities tailored to each
                            individual's
                            specific requirements, we need to process, analysis &amp; retain certain information about
                            you
                            to ensure we can provide our service to the best of our ability. This information may
                            include:</p>
                        <p>2.1.1 Uppon registering with our website, email &amp; additional website links:</p>
                        <p>2.1.2 Information contained in your resume &amp; data collection forms and any other uploads
                            to
                            our website or public domain information such as (LinkedIn or job boards, etc.)</p>
                        <p>2.1.3 Information provided to our companies email addresses</p>
                        <p>2.1.4 Data supplied at will or obtained from any social media platforms</p>
                        <p>2.1.5 Data collected during the phone, Whatsapp, SMS or instant messenger conversations</p>
                        <p>2.1.6 Data gathered using cookies</p>
                        <p>2.2 Information that may also be provided to us that may be classed as special data may be
                            recorded &amp; stored internally. This could include information about previous medical
                            conditions, working relationships, convictions, outstanding penalties/charges. You are not
                            obligated to provide this information; however, if you do, The Company shall view this as,
                            you
                            giving your explicit consent to provide this information to any relevant client. And for us
                            to
                            use the information in ways outlined in this policy.</p>
                        <p>2.3 The Company reserves the right to request the completion of surveys to help The Company
                            improve service quality or to obtain further information on your background. You are not
                            obligated to provide this information; however, it is advised that you do so to help us
                            deliver
                            the best service possible.</p>
                        <p>2.4 Any additional information provided by yourself such as (without limitation to)
                            referrals,
                            family members, past employers, colleagues, friends or next of kin/persons to contact in an
                            emergency, are to be provided on the basis that you already have these persons prior
                            approval to
                            provide their details before submitting to The Company.</p>
                        <p>2.5 Any amendments/updates that you wish to provide us, or if you wish to be removed from our
                            database or specific data to be removed, please contact us at <a
                                href="mailto:info@performance-energy.com"
                                target="_blank" rel="noopener noreferrer">info@performance-energy.com</a>.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>3.0 Legal</h3>
                        <p>3.1 Since you have provided your information to us without raising prior concerns regarding
                            the
                            storage &amp; usage of this information, we are permitted to utilize this data accordingly
                            to
                            provide the services set out by the business. You have the right to request removal from the
                            database and/or removal of any data collected.</p>
                        <p>3.2 Information will only be processed as outlined in our services to benefit The Company
                            and/or
                            the person obtaining the service.</p>
                        <p>3.3 Data sharing with your next of kin/emergency contact persons, will be shared on the basis
                            of
                            you agreeing to do so. By completing a next of Kin data from provided by The Company
                            authorizes
                            the sharing of confidential information with these persons only, in the case that you are
                            involved in a medical emergency or deceased.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>4.0 How your information is used</h3>
                        <p>4.1 We may use your information, as described above as follows:</p>
                        <p>4.1.1 To provide you with recruitment services</p>
                        <p>4.1.2 To provide you with Profile Writing Services</p>
                        <p>4.1.3 To verify your information</p>
                        <p>4.1.4 To respond to your requests for information</p>
                        <p>4.1.5 To market you to clients &amp; other industry-related persons</p>
                        <p>4.1.6 To match you with job opportunities</p>
                        <p>4.1.7 To provide you with marketing information</p>
                        <p>4.1.8 To keep you informed on our company information</p>
                        <p>4.1.9 To improve our website &amp; services</p>
                        <p>4.1.10 For research, analysis, testing, monitoring, risk management and administrative
                            purposes –
                            in such circumstances, the data we use will be aggregated so that it cannot be used to
                            identify
                            you. We may disclose this aggregated data to third parties for marketing, advertising or
                            other
                            promotional purposes.</p>
                        <p>4.2 It may also be necessary for us to pass on or allow access to your information:</p>
                        <p>4.2.1 To prospective employers and/or clients for the purposes of recruitment, we will only
                            do
                            this with your consent;</p>
                        <p>4.2.2 To our suppliers, contractors and professional advisors where this is necessary for
                            them to
                            provide services and facilities to us and/or you;</p>
                        <p>4.2.3 To any purchaser of all or part of our business to which this website relates;</p>
                        <p>4.2.4 Where we are required to do so by law, court order or another legal process;</p>
                        <p>4.2.5 Where acting in good faith, we believe disclosure is necessary to assist in the
                            investigation or reporting of suspected illegal or other wrongful activity;</p>
                        <p>4.2.6 To protect and defend our rights or property.</p>
                        <p>4.3 We may also pass on your information to 3rd parties or clients to enable them to contact
                            you
                            with relevant information or job opportunities.</p>
                        <p>4.4 If you are placed with one of our clients or hired as a direct employee following the
                            support
                            of our services, we will keep your information on file until you request that it is
                            removed.</p>
                        <p>4.5 We are committed to following the appropriate steps to protect all information provided
                            or
                            obtained from misuse, loss, tampering and unauthorized access. If you suspect that any of
                            the
                            above has occurred, please contact us immediately at <a
                                href="mailto:info@performance-energy.com"
                                target="_blank" rel="noopener noreferrer">info@performance-energy.com</a>.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>5.0 Your rights</h3>
                        <p>5.1 You have the right to:</p>
                        <p>5.1.1 Request the removal of your data from our storage</p>
                        <p>5.1.2 Request a copy of your data that is stored, we will respond with this data via email
                            within
                            45days of the initial written requests.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>6.0 Links to other websites &amp; RSS</h3>
                        <p>6.1 Privacy Policy only applies to this website. If you link to another website or to an RSS
                            feed
                            from our website, you should remember to read and understand that website’s Cookie and
                            Privacy
                            Policy as well. We are not responsible for any use of your information that is made by
                            websites
                            or RSS feeds belonging to third parties.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>7.0 Contact Us</h3>
                        <p>7.1 If you have any questions about this Privacy Policy, please contact <a
                            href="mailto:info@performance-energy.com" target="_blank" rel="noopener noreferrer">info@performance-energy.com</a>.
                        </p>
                    </div>
                    <div className={infoBlock}>
                        <h3>8.0 Email &amp; Phone Privacy Policy</h3>
                        <p>8.1 We monitor all incoming &amp; outgoing email &amp; phone traffic data also with the
                            consent
                            of our staff for the purposes of security and staff training. Messages sent from us and our
                            employees contain confidential information and shall not be forwards, shared or discussed
                            with
                            any 3rd parties without prior consent. If you are not the intended recipient you should not
                            disseminate, distribute, copy or forward any part of the information shared but should
                            delete it
                            immediately and confirm in writing with the Company that this has been done.</p>
                        <p>8.1.2 If the unintended recipient does not follow the outlined privacy statement this is
                            considered a material breach of the Privacy Policy and the Company reserves the right to
                            oppose
                            legal action.</p>
                        <p>8.1.3 Should the intended recipient not follow the outlined privacy statement this is
                            considered
                            a material breach of the Privacy Policy and the Company reserves the right to oppose legal
                            action.</p>
                    </div>

                </div>
            </section>


        </Layout>
    )
}
